
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
.pg-homepage
{

    .block-text
    {
        @include eq-mobile
        {
            margin-top: 50px;
            margin-bottom: 50px;
        }
        
        @include at-tablet
        {
            margin-top: 100px;
            margin-bottom: 100px;
        }
    }

}