
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
.im-block-video 
{
    &__container
    {
        position: relative;
    }

    &__media 
    {
        display: block;
        @include size(100%, auto);
        aspect-ratio: 1.8;

        video,
        picture
        {
            display: block;
            @include size(100%);
            object-fit: cover;
        }
    }

    &__button-play, 
    &__button-modal 
    {
        @include center();
        @include size(100px);
        opacity: 1;
        visibility: visible;
        transition: visibility $timeFast ease-in-out, opacity $timeFast ease-in-out;
        cursor: pointer;

        &.hide 
        {
            opacity: 0;
            visibility: hidden;
        }

        .icon-play 
        {
            @include center();
            width: 100%;
            height: auto;
        }
        
        svg
        {
            fill: $white;
        }
        
        @include eq-mobile
        {
            @include size(45px);
        }

        @include at-tablet
        {
            @include size(100px);
        }
    }
}