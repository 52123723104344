
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Hero Slider Offers
//--------------------------------------------------------------------//

.hero-slider-offers
{
    position: relative;
    overflow: hidden;
    height:  var(--vh);

    &__bg
    {
        position: absolute;
        top: 0px;
        left: 0px;
        @include size(100%);
        background-color: $primary_dark;
        z-index: 1;

        picture
        {
            display: block;
            @include size(100%);

            img
            {
                display: block;
                @include size(100%);
                object-fit: cover; 
            }
        }

        &-video
        {
            display: block;
            @include size(100%);
            
            video
            {
                display: block;
                @include size(100%);
                object-fit: cover;
            }
        }
    }

    &__top
    {
        height:  var(--vh);

        .swiper-slide
        {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(center);
            @include align-items(center);

            picture
            {
                display: block;
                transition: width $timeMedium $easeCubicInOut;

                img
                {
                    display: block;
                    @include size(100%, auto);
                }
            }
        }
    }

    &__btm
    {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;

        .swiper-slide
        {
            @include flexbox();
            @include flex-wrap(wrap);
            @include align-items(flex-end);
            @include justify-content(space-between); 
            color: $white;

            .type
            {
                display: inline-block;
                padding: 5px 10px;
                border-radius: 5px;
                color: $accent;
                background-color: $white;
                text-transform: uppercase;

                svg,
                .label
                {
                    display: inline-block;
                    vertical-align: middle;
                }

                svg
                {
                    margin-right: 3px;
                    @include size(14px);
                    fill: $accent;
                }
            }

            .name,
            .power
            {
                display: block;
                font-weight: $font-weight-bold;
            }

            .features
            {
                li
                {
                    display: inline-block;

                    &:not(:last-child)
                    {
                        margin-right: 20px;
                    }

                    svg,
                    span
                    {
                        display: inline-block;
                        vertical-align: middle;
                    }

                    svg
                    {
                        fill: $secondary;
                        @include size(20px);
                    }

                    span
                    {
                        font-size: em(13px);
                        line-height: em(20px, 13px);
                        font-weight: $font-weight-semibold;
                    }
                }
            }

            .price
            {
                @include flexbox();
                @include flex-wrap(wrap);

                &__value,
                &__currency
                {
                    display: inline-block;
                    font-weight: $font-weight-extrabold;
                    color: $white;
                }

                &__currency
                {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    @include align-items(center);

                    span
                    {
                        &:last-child
                        {
                            margin-left: 3px;
                        }
                    }
                }

                &__vat
                {
                    display: block;
                    padding-left: 3px;
                    color: $white;
                    font-weight: $font-weight-bold;
                    text-align: left;
                }
            }

            &.centered
            {
                .type
                {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    @include align-items(center);
                    width: fit-content;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &__progress
    {
        position: absolute;
        left: 0;
        bottom: 0px;
        @include size(0%, 5px);
        background: $secondary_accent_h;
        z-index: 5;
    }

    @include eq-mobile
    {
        min-height: 500px;

        &__bg
        {
            picture
            {
                img
                {
                    object-position: center -100px;
                }
            }

            &-video
            {   
                video
                {
                    object-position: center -55px;
                    transform: scale(1.2);
                }
            }
        }

        &__top
        {
            .swiper-slide
            {
                picture
                {
                    margin-bottom: 140px;
                }

                &:not(.swiper-slide-active)
                {
                    picture
                    {
                        @include size(70%, auto);
                        max-width: 400px;
                    }
                }

                &.swiper-slide-active
                {
                    picture
                    {
                        @include size(100%, auto);
                        max-width: 400px;
                    }
                }
            }
        }

        &__btm
        {
            .swiper-slide
            {
                padding: 15px 15px 40px;
            }

            .wrap-left,
            .wrap-right
            {
                width: 100%;
            }

            .type
            {
                margin-bottom: 12px;
            }

            .name
            {
                font-size: em(35px);
                line-height: em(41px, 35px);
            }

            .power
            {
                font-size: em(13px);
                line-height: em(20px, 13px);
            }

            .features
            {
                margin-top: 20px;

                li
                {
                    display: inline-block;

                    &:not(:last-child)
                    {
                        margin-right: 15px;
                    }
                }
            }

            .price
            {
                margin-top: 20px;
                margin-bottom: 20px;

                &__value
                {
                    font-size: em(40px);
                    line-height: em(47px, 40px);
                }

                &__currency
                {
                    vertical-align: top;
                    margin-top: 9px;

                    span
                    {
                        &:first-child
                        {
                            font-size: em(16px);
                            line-height: em(16px, 16px);
                        }

                        &:last-child
                        {
                            font-size: em(10px);
                            line-height: em(12px, 10px);
                        }
                    }
                }

                &__vat
                {
                    font-size: em(10px);
                    line-height: em(20px, 10px);
                }
            }

            .button-primary
            {
                display: block;
                width: 100%;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
            }

            .centered
            {
                .wrap-left,
                .wrap-right
                {
                    text-align: center;
                }

                .button-primary
                {
                    margin-top: 20px;
                }
            }
        }
    }

    @include at-tablet
    {
        min-height: 750px;

        &__top
        {
            .swiper-slide
            {
                &:not(.swiper-slide-active)
                {
                    picture
                    {
                        @include size(60%, auto);
                        max-width: 720px;
                    }
                }

                &.swiper-slide-active
                {
                    picture
                    {
                        @include size(80%, auto);
                        max-width: 720px;
                    }
                }
            }
        }

        &__btm
        {
            .swiper-slide
            {
                padding: 40px;
                height: auto;

                .wrap-left,
                .wrap-right
                {
                    width: 50%;
                }

                .wrap-right
                {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    @include justify-content(flex-end);
                    text-align: right;
                }

                .type
                {
                    margin-bottom: 14px;
                }

                .power
                {
                    font-size: em(15px);
                    line-height: em(22px, 15px);
                }

                .features
                {
                    margin-top: 16px;
                }

                .price
                {
                    &__value
                    {
                        font-size: em(55px);
                        line-height: em(55px, 55px);
                    }

                    &__currency
                    {
                        vertical-align: top;
                        margin-top: 5px;

                        span
                        {
                            &:first-child
                            {
                                font-size: em(27px);
                                line-height: em(27px, 27px);
                            }

                            &:last-child
                            {
                                font-size: em(15px);
                                line-height: em(18px, 15px);
                            }
                        }
                    }

                    &__vat
                    {
                        font-size: em(13px);
                        line-height: em(16px, 13px);
                    }
                }

                &.centered
                {
                    .wrap-left,
                    .wrap-right
                    {
                        width: 100%;
                        text-align: center;
                        @include justify-content(center);
                    }

                    .button-primary
                    {
                        margin-left: unset;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    
    @include eq-tablet
    {
        &__btm
        {
            .swiper-slide
            {
                .name
                {
                    font-size: em(35px);
                    line-height: em(40px, 35px);
                }

                .button-primary
                {
                    margin-top: 10px;
                    min-width: 260px;
                }
            }
        }
    }

    @include at-desktop
    {
        &__btm
        {
            .swiper-slide
            {
                .name
                {
                    font-size: em(40px);
                    line-height: em(48px, 40px);
                }

                .button-primary
                {
                    margin-left: 34px;
                    min-width: 250px;
                }

                &.centered
                {
                    .name
                    {
                        font-size: em(65px);
                        line-height: em(70px, 65px);
                    }
                }
            }
        }
    }

    @media screen and (max-height: 850px)
    {
        &__btm
        {
            .swiper-slide
            {
                &.centered
                {
                    .type
                    {
                        margin-bottom: 10px;

                        .label
                        {
                            font-size: em(12px);
                            line-height: em(16px, 12px);
                        }
                    }

                    .name
                    {
                        font-size: em(35px);
                        line-height: em(40px, 35px);
                    }

                    .features
                    {
                        margin-top: 8px;
                    }

                    .button-primary
                    {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}

//--------------------------------------------------------------------//