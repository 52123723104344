
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Hero Simple
//--------------------------------------------------------------------//

.hero-simple
{
    position: relative;
    background-color: $primary;

    &__media
    {
        position: absolute;
        top: 0px;
        left: 0px;
        @include size(100%);
        z-index: 1;

        picture
        {
            display: block;
            @include size(100%);

            img
            {
                display: block;
                @include size(100%);
                object-fit: cover;
            }
        }
    }

    &__content
    {
        position: relative;
        color: $white;
        z-index: 2;
    }
    &__full
    {
        position: relative;
        color: $white;
        z-index: 2;
    }
    &__label
    {
        display: block;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }
    .button-primary {
        margin: 30px 0 0;
    }
    .logo-hero {
        display: inline-block;
        margin: 0 0 20px;
        width: 250px;
        height: auto;

    }
    @include eq-mobile
    {
        &__content
        {
            min-height: 500px;
        }
        &__full
        {
            min-height: 500px;
            height: 500px;
        }
        &__label
        {
            margin-bottom: 10px;
        }
    }

    @include at-tablet
    {
        
        &__content
        {
            padding-top: 100px;
            padding-bottom: 100px;
            min-height: 700px;
        }
        &__full
        {
            min-height: 700px;
            height: calc(100vh - 106px);
        }
        &__label
        {
            margin-bottom: 16px;
        }
    }
}

//--------------------------------------------------------------------//