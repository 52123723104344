
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Card Car Big
//--------------------------------------------------------------------//

.card-car-big
{
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &__top
    {
        &-label
        {
            position: absolute;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 5px;
            color: $accent;
            background-color: $white;
            text-transform: uppercase;
            z-index: 3;
        }

        &-media
        {
            position: relative;
            z-index: 2;

            picture
            {
                display: block;
                @include size(100%, auto);
                aspect-ratio: 1.857142;

                img
                {
                    display: block;
                    @include size(100%);
                    object-fit: cover;
                }
            }
        }
    }

    &__middle
    {
        .name,
        .power
        {
            display: block;
            font-weight: $font-weight-bold;
        }

        .features
        {
            li
            {
                svg,
                span
                {
                    display: inline-block;
                    vertical-align: middle;
                }

                svg
                {
                    @include size(20px);
                }

                span
                {
                    font-size: em(13px);
                    line-height: em(20px, 13px);
                    font-weight: $font-weight-semibold;
                }
            }
        }
    }

    .price
    {
        &__value,
        &__currency
        {
            display: inline-block;
            font-weight: $font-weight-bold;
            color: $secondary;
        }

        &__monthly,
        &__delete
        {
            color: $gray_dark;
        }

        &__delete
        {
            display: block;
        }
    }

    @include eq-mobile
    {
        &__top
        {
            position: relative;
            padding-top: 26.3%;

            &::before
            {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                @include size(100%, calc(100% - 16%));
                background: $secondary_accent_v;
            }

            &-label
            {
                top: 10px;
                left: 10px;
            }

            &-media
            {
                margin-left: auto;
                margin-right: auto;
                width: 78.9%;
            }
        }

        &__middle
        {
            color: $primary;

            .name
            {
                font-size: em(35px);
                line-height: em(41px, 35px);
            }

            .power
            {
                font-size: em(13px);
                line-height: em(20px, 13px);
                color: $secondary;
            }

            .features
            {
                margin-top: 20px;

                li
                {
                    display: inline-block;

                    &:not(:last-child)
                    {
                        margin-right: 15px;
                    }

                    svg
                    {
                        fill: $secondary;
                    }
                }
            }
        }

        &__btm
        {
            margin-top: 20px;

            &-right
            {
                max-width: calc(100% - 160px);
            }
        }

        .price
        {
            &__value
            {
                font-size: em(40px);
                line-height: em(47px, 40px);
            }

            &__currency
            {
                vertical-align: top;
                margin-top: 9px;
                font-size: em(16px);
                line-height: em(16px, 16px);
            }

            &__monthly
            {
                margin-left: -14px;
                font-size: em(10px);
                line-height: em(20px, 10px);
            }

            &__delete
            {
                font-size: em(12px);
                line-height: em(20px, 12px);
            }
        }
    }

    @media screen and (max-width:420px)
    {
        .button-primary
        {
            text-align: center;
            padding: 16px 20px 16px !important;
        } 
    }

    @include at-tablet
    {
        &__inner
        {
            position: relative;
        }

        &__top
        {
            &-label
            {
                top: 20px;
                left: 20px;
            }

            &-media
            {
                @include centerX();
                max-width: 580px;
            }
        }

        &__middle
        {
            padding: 60px 20px 14.62%;
            color: $white;
            background: $accent_secondary_h;

            .power
            {
                font-size: em(15px);
                line-height: em(22px, 15px);
            }

            .features
            {
                margin-top: 28px;
                max-width: 40%;

                li
                {
                    span
                    {
                        color: $white;
                    }

                    svg
                    {
                        fill: $white;
                    }

                    &:not(:first-child)
                    {
                        margin-top: 10px;
                    }
                }
            }
        }

        &__btm
        {
            margin-top: 10px;
        }

        .price
        {
            &__value
            {
                font-size: em(55px);
                line-height: em(55px, 55px);
            }

            &__currency
            {
                vertical-align: top;
                margin-top: 5px;
                font-size: em(27px);
                line-height: em(27px, 27px);
            }

            &__monthly
            {
                margin-left: -22px;
            }

            &__monthly,
            &__delete
            {
                font-size: em(15px);
                line-height: em(18px, 15px);
            }
        }
    }

    @include eq-tablet
    {
        &__top
        {
            &-media
            {
                width: 55%;
                bottom: 7%;
            }
        }

        &__middle
        {
            .name
            {
                font-size: em(35px);
                line-height: em(40px, 35px);
            }
        }
    }

    @include at-desktop
    {
        &__top
        {
            &-media
            {
                width: 63%;
                bottom: 4%;
            }
        }

        &__middle
        {
            max-height: 400px;

            .name,
            .power
            {
                max-width: 50%;
            }

            .name
            {
                font-size: em(40px);
                line-height: em(48px, 40px);
            }
        }
    }
}

//--------------------------------------------------------------------//