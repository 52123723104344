
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Block Text
//--------------------------------------------------------------------//

.block-text
{
    &__container
    {
        position: relative;
    }

    &__title
    {
        margin-bottom: 5px;
        font-weight: $font-weight-bold;
    }

    &__subtitle
    {
        color: $secondary;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    @include eq-mobile
    {
        &__title
        {
            font-size: em(30px);
            line-height: em(38px, 30px);
        }

        &__text
        {
            margin-top: 50px;
        }

        &__cta
        {
            margin-top: 30px;
        }
    }

    @include at-tablet
    {
        &__title
        {
            font-size: em(40px);
            line-height: em(48px, 40px);
        }

        &__text
        {
            margin-top: 45px;
        }

        &__cta
        {
            margin-top: 40px;
        }
    }
}

//--------------------------------------------------------------------//