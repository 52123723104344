//##########################################//
//                                          //
//      VARIABILI CROMATICHE SITO           //
//                                          //
//##########################################//


// Default
//--------------------------------------------------------------------///
$black:     #000000;
$white:     #FFFFFF;


// Design System
//--------------------------------------------------------------------//
$primary:          #18305B;
$primary_dark:     #070328;
$primary_medium:   #011834;
$primary_light:    #005187;
$primary_bright:   #034397;
$secondary:        #00A0DF;
$secondary_dark:   #014560;
$secondary_medium: #0079A8;
$secondary_light:  #E8F9FF;
$secondary_bright: #A0E4FF;
$accent:           #C8017D;
$accent_dark:      #43002A;
$accent_medium:    #810050;
$accent_light:     #EF63BA;
$accent_bright:    #F418A1;
$light:            #FAFAFA;
$line:             #D8D8D8;
$medium:           #a9a9a9;
$dark:             #666666;
$grey:             #8A959C;
$gray_dark:        #979797;
$gray_light:       #E3E3E3;
$gray_bright:      #F4F5F6;
$red:              #E20B0B;
$green:            #5DBE7B;
$yellow:           #F5CF05;

// Gradient
//--------------------------------------------------------------------//
$primary_h_dark_light: linear-gradient(270deg, $primary_light 0%, $primary_dark 100%);
$primary_h_light_dark: linear-gradient(270deg, $primary_dark 0%, $primary_light 100%);
$primary_v_light_dark: linear-gradient(0deg, $primary_light 0%, $primary_dark 100%);
$primary_r_light_dark: radial-gradient(50% 50% at 50% 50%, $primary_light 0%, $primary_dark 100%);

$accent_secondary_h: linear-gradient(90deg, $accent 0%, $secondary 100%);
$secondary_accent_h: linear-gradient(90deg, $secondary 0%, $accent 100%);
$accent_secondary_v: linear-gradient(180deg, $accent 0%, $secondary 100%);
$secondary_accent_v: linear-gradient(180deg, $secondary 0%, $accent 100%);

$gray_white_h: linear-gradient(90deg, $gray_light 0%, $white 100%);
$white_gray_h: linear-gradient(90deg, $white 0%, $gray_light 100%);
$gray_white_v: linear-gradient(180deg, $gray_light 0%, $white 100%);
$white_gray_v: linear-gradient(180deg, $white 0%, $gray_light 100%);