//##########################################//
//                                          //
//     VARIABILI TYPOGRAPHY DEL SITO        //
//                                          //
//##########################################//


// FONT
//--------------------------------------------------------------------//

$font-family-primary: var(--font-family) !default;
$font-family-title:   $font-family-primary;

$font-weight-light:     300 !default;
$font-weight-regular:   400 !default;
$font-weight-medium:    500 !default;
$font-weight-semibold:  600 !default;
$font-weight-bold:      700 !default;
$font-weight-extrabold: 800 !default;

$line-height-base:            1em;
$base-font-size:              16px;



// Variabili globali Typography
//--------------------------------------------------------------------//

%h1
{
    margin: 0px;
    font-family: $font-family-title;
    font-weight: $font-weight-semibold;
}

%h2
{
    margin: 0px;
    font-family: $font-family-title;
    font-weight: $font-weight-semibold;
}

%h3
{
    margin: 0px;
    font-family: $font-family-title;
    font-weight: $font-weight-semibold;
}

%h4
{
    margin: 0px;
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
}

%h5
{
    margin: 0px;
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
}

%h6
{
    margin: 0px;
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
}

%p
{
    margin: 0px;
}

%data_bold
{
    font-size: em(12px);
    line-height: em(20px, 12px);
    font-weight: $font-weight-bold;
}

%text_link
{
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
}

%button_base
{
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 16px 30px 16px !important;
    font-size: em(15px) !important;
    line-height: em(18px, 15px) !important;
    min-width: 100px;
    text-decoration: none;
    font-family: $font-family-primary !important;
    font-weight: $font-weight-bold !important;
    border-radius: 5px;
    text-align: center;
    transition: color $timeFast $easeQuadInOut,
                box-shadow $timeFast $easeQuadInOut,
                background-color $timeFast $easeQuadInOut;
    z-index: 1;
    border: none;
    background-color: transparent;
}

%primary_button
{
    @extend %button_base;
}

%secondary_button
{
    @extend %button_base;
}

%text_button
{
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    font-size: em(15px);
    line-height: em(17, 15px);
    font-weight: $font-weight-bold;
    text-decoration: none;
    transition: color $timeFast $easeQuadInOut;
}

%select_form
{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
    cursor: pointer;
    @include size(100%, auto);
    padding: 16px 60px 16px 20px; 
    font-size: em(15px);
    line-height: em(18px, 15px);
    font-weight: $font-weight-semibold;
    font-family: $font-family-primary;
    color: $primary;
    background-color: $gray_bright;
    border: 0px;
    outline: none;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: calc(100% - 13px) center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2318305B' d='m15.724 11.033-3.693 3.693a.934.934 0 0 1-1.322 0l-3.693-3.693c-.59-.59-.172-1.597.66-1.597h7.387c.833 0 1.25 1.008.661 1.597Z'/%3E%3C/svg%3E");
    @include box-shadow(0px, 0px, 0px, 2px, transparent, true);
    transition: color $timeFast $easeCubicInOut,
                box-shadow $timeFast $easeCubicInOut;

    &:not(.disabled):focus,
    &:not([disabled]):focus,
    &.open
    {
        color: $secondary;
        @include box-shadow(0px, 0px, 0px, 2px, $secondary, true);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2300A0DF' d='m7.016 13.404 3.693-3.694a.934.934 0 0 1 1.322 0l3.693 3.694c.59.589.172 1.596-.66 1.596H7.676c-.833 0-1.25-1.007-.661-1.596Z'/%3E%3C/svg%3E%0A");

        ~ label
        {
            color: $secondary;
        }
    }

    &.disabled,
    &:disabled
    {
        pointer-events: none;
        color: $gray_light !important;
        -webkit-box-shadow: inset 0px 0px 0px 2px transparent !important;
        -moz-box-shadow: inset 0px 0px 0px 2px transparent !important;
        box-shadow: inset 0px 0px 0px 2px transparent !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23E3E3E3' d='m15.724 11.033-3.693 3.693a.934.934 0 0 1-1.322 0l-3.693-3.693c-.59-.59-.172-1.597.66-1.597h7.387c.833 0 1.25 1.008.661 1.597Z'/%3E%3C/svg%3E") !important;

        & ~ label
        {
            pointer-events: none;
            color: $gray_light !important;
        }
    }

    
}

%input_checkbox_form
{
    @include checkbox($gray_light, $secondary);
}

%input_form
{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
    cursor: pointer;
    @include size(100%, auto);
    padding: 16px 60px 16px 20px; 
    font-size: em(15px);
    line-height: em(18px, 15px);
    font-weight: $font-weight-semibold;
    font-family: $font-family-primary;
    color: $primary;
    background-color: $gray_bright;
    border: 0px;
    outline: none;
    border-radius: 5px;
    transition: color $timeFast $easeCubicInOut,
                box-shadow $timeFast $easeCubicInOut;

    &::placeholder { 
        color: $primary;
        opacity: 1;
    }
      
    &:-ms-input-placeholder { 
        color: $primary;
    }
    
    &::-ms-input-placeholder {
        color: $primary;
    }

    &:not(.disabled):focus,
    &:not([disabled]):focus,
    &.open
    {
        @include box-shadow(0px, 0px, 0px, 2px, $secondary, true);

        ~ label
        {
            color: $secondary;
        }
    }

    &.disabled,
    &:disabled
    {
        pointer-events: none;
        color: $gray_light !important;
        -webkit-box-shadow: inset 0px 0px 0px 2px transparent !important;
        -moz-box-shadow: inset 0px 0px 0px 2px transparent !important;
        box-shadow: inset 0px 0px 0px 2px transparent !important;

        & ~ label
        {
            pointer-events: none;
            color: $gray_light !important;
        }
    }
}

%textarea_form
{
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    display: inline-block;
    cursor: pointer;
    @include size(100%, auto);
    padding: 16px 60px 16px 20px; 
    font-size: em(15px);
    line-height: em(18px, 15px);
    font-weight: $font-weight-semibold;
    font-family: $font-family-primary;
    color: $primary;
    background-color: $gray_bright;
    border: 0px;
    outline: none;
    resize: none;
    border-radius: 5px;

    &::placeholder { 
        color: $primary;
        opacity: 1;
    }
      
    &:-ms-input-placeholder { 
        color: $primary;
    }
    
    &::-ms-input-placeholder {
        color: $primary;
    } 

    &:not(.disabled):focus,
    &:not([disabled]):focus,
    &.open
    {
        @include box-shadow(0px, 0px, 0px, 2px, $secondary, true);

        ~ label
        {
            color: $secondary;
        }
    }
}

%input_radio_form
{
    @include radio($gray_light, $secondary);
}

//--------------------------------------------------------------------//