
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Notification
//--------------------------------------------------------------------//

.notification-target
{
    position: sticky;
    top: 80px;
    z-index: 10;
    transition: top 0.6s ease-in-out;
}

.notification
{
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;

    .icon-status
    {
        svg
        {
            display: inline-block;
            @include size(20px);
        }
    }

    span
    {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        font-weight: $font-weight-bold;
    }

    .icon-dismiss
    {
        cursor: pointer;

        svg
        {
            display: inline-block;
            @include size(20px);
            fill: $primary;
        }
    }

    &.error,
    &.success,
    &.info
    {
        color: $white;

        .icon-status svg
        {
            fill: $white;
        }
    }

    &.error
    {
        background-color: $red;
    }
    
    &.success
    {
        background-color: $green;
    }
    
    &.attention
    {
        color: $primary;
        background-color: $yellow;

        .icon-status svg
        {
            fill: $primary;
        }
    }
    
    &.info
    {
        background-color: $secondary;
    }
}

body
{
    &.scroll-down
    {
        .notification-target
        {
            top: 20px;
        }
    } 

    &.scroll-up
    {
        .notification-target
        {
            top: 80px;
        }
    } 
}

//--------------------------------------------------------------------//