
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Block Form
//--------------------------------------------------------------------//

.block-form
{
    &__heading,
    &__row
    {
        &:not(:last-child)
        {
            border-bottom: 1px solid $gray_bright;
        }
    }

    &__heading
    {
        .type
        {
            display: inline-block;
            padding: 5px 10px;
            border-radius: 5px;
            color: $white;
            background-color: $accent;
            text-transform: uppercase;
        }

        .name
        {
            color: $accent;
            font-weight: $font-weight-bold;
        }

        .info
        {
            font-weight: $font-weight-bold;

            span
            {
                display: block;
            }
        }

        .price
        {
            &__value,
            &__currency
            {
                display: inline-block;
                font-weight: $font-weight-extrabold;
                color: $primary;
            }

            &__monthly
            {
                color: $primary;
                font-weight: $font-weight-bold;
            }
        }
    }

    &__row
    {
        &-inner
        {
            margin-left: -12.5px;
            margin-right: -12.5px;

            > *
            {
                @include flexbox();
                @include align-items(flex-end);
                padding: 0px 12.5px;

                .form-group
                {
                    width: 100%;
                }
            }
        }
    }

    @include eq-mobile
    {
        &__heading,
        &__row
        {
            &:not(:last-child)
            {
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }

        &__heading
        {
            .type
            {
                margin-bottom: 20px;
            }

            .price
            {
                margin-top: 20px;

                &__value
                {
                    font-size: em(40px);
                    line-height: em(47px, 40px);
                }

                &__currency
                {
                    vertical-align: top;
                    margin-top: 9px;
                    font-size: em(16px);
                    line-height: em(16px, 16px);
                }

                &__monthly
                {
                    margin-left: -14px;
                    font-size: em(10px);
                    line-height: em(20px, 10px);
                }
            }

            .wrap-name-info
            {
                .name
                {
                    font-size: em(30px);
                    line-height: em(38px, 30px);
                }

                .info
                {
                    margin-top: 15px;
                }
            }
        }

        &__row
        {
            &-inner
            {
                > *:not(:nth-child(1))
                {
                    margin-top: 28px;
                }
            }
        }
    }

    @include at-tablet
    {
        &__heading,
        &__row
        {
            &:not(:last-child)
            {
                padding-bottom: 40px;
                margin-bottom: 45px;
            }
        }

        &__heading
        {
            .type
            {
                margin-bottom: 34px;
            }

            .price
            {
                &__value
                {
                    font-size: em(55px);
                    line-height: em(55px, 55px);
                }

                &__currency
                {
                    vertical-align: top;
                    margin-top: 5px;
                    font-size: em(27px);
                    line-height: em(27px, 27px);
                }

                &__monthly
                {
                    margin-left: -22px;
                    font-size: em(15px);
                    line-height: em(18px, 15px);
                }
            }

            .wrap-name-info
            {
                @include flexbox();
                @include flex-wrap(wrap);
                @include align-items(center);

                .name
                {
                    padding-right: 40px;
                    font-size: em(40px);
                    line-height: em(48px, 40px);
                    max-width: 60%;
                    border-right: 1px solid $primary;
                }

                .info
                {
                    padding-left: 40px;
                    max-width: 40%;
                }
            }
        }
    }

    @include eq-tablet
    {
        &__heading
        {
            .wrap-name-info
            {
                margin-bottom: 40px;
            }
        }

        &__row
        {
            &-inner
            {
                > *:not(:nth-child(1))
                {
                    margin-top: 28px;
                }
            }
        }
    }

    @include at-desktop
    {
        &__row
        {
            &-inner
            {
                > *:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3))
                {
                    margin-top: 28px;
                }
            }
        }

        .required-info
        {
            text-align: right;
        }
    }
}

form.form-has-errors
{
    .block-form
    {
        &__row
        {
            &-inner
            {
                > *
                {
                    @include align-items(flex-start);
                }
            }
        }
    }
}

//--------------------------------------------------------------------//