
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Block Cards
//--------------------------------------------------------------------//

.card-blog
{
    @include size(100%);
    border-radius: 5px;
    box-shadow: 0px 10px 40px rgba(0, 104, 150, 0.2);

    &.small &
    {
        &__content
        {
            padding: 20px;
        }

        &__title
        {
            @extend %h5;
        }

        &__text
        {
            margin-bottom: 20px;
        }

        &__media
        {
            picture
            {
                aspect-ratio: 2.5;
            }
        }
    }
    
    &.medium &
    {
        &__content
        {
            padding: 30px 20px;
        }

        &__title
        {
            @extend %h4;
        }

        &__text
        {
            margin-bottom: 30px;
        }

        &__media
        {
            picture
            {
                aspect-ratio: 2.333333;
            }
        }
    }
    
    &.big &
    {
        &__content
        {
            padding: 30px 20px;
        }

        &__title
        {
            @extend %h4;
        }

        &__text
        {
            margin-bottom: 30px;
        }
        
        &__media
        {
            picture
            {
                aspect-ratio: 2;
            }
        }
    }

    &__inner
    {
        position: relative;
        background-color: $white;
        overflow: hidden;
        border-radius: 5px;
    } 
    
    &__icon-cta
    {
        position: absolute;
        top: 13px;
        right: 17px;
        z-index: 3;

        svg
        {
            fill: $white;
            @include size(20px);
        }
    }

    &__media
    {
        position: relative;
        background: linear-gradient(90deg, #C6007E 0%, #00A3E0 100%);
        
        picture
        {
            display: block;
            @include size(100%, auto);

            img
            {
                display: block;
                @include size(100%);
                mix-blend-mode: lighten;
            }
        }
    }

    &__title
    {
        margin-bottom: 10px !important;
    }

    &__text
    {
        color: $gray_dark;
        font-size: em(15px);
        line-height: em(18px, 15px);
    }

    &__cta
    {
        display: block;
        width: 100%;
    }

    &__subcta
    {
        display: block;
        margin-top: 15px;
    }
}

//--------------------------------------------------------------------//