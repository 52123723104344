
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Block Top Bar
//--------------------------------------------------------------------//

.block-top-bar
{
    position: relative;
    @include size(100%, auto);
    background-color: $accent;
    z-index: 21;

    &__content
    {
        padding: 4px 0px;
        font-size: em(14px);
        line-height: em(22px, 14px);
        font-weight: $font-weight-bold;
        color: $white;
    }

    @include eq-mobile
    {
        &__content
        {
            font-size: em(12px);
            line-height: em(20px, 12px);
        }
    }

    @include at-tablet
    {
        &__content
        {
            font-size: em(14px);
            line-height: em(22px, 14px);
        }
    }
}

//--------------------------------------------------------------------//