
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Block Banner Image
//--------------------------------------------------------------------//

.block-banner-image
{
    &__container
    {
        position: relative;
    }

    &__media
    {
        position: absolute;
        top: 0px;
        height: 100%;
        background-color: $primary;
        overflow: hidden;
        border-radius: 10px;
        z-index: 1;

        picture
        {
            display: block;
            @include size(100%);

            img
            {
                display: block;
                @include size(100%);
                object-fit: cover;
            }
        }
    }

    &__content
    {
        position: relative;
        min-height: 600px;
        color: $white;
        z-index: 2;
    }

    &__text
    {
        margin-bottom: 40px;
    }

    &__cta
    {
        margin-top: 40px;
    }

    @include eq-mobile
    {
        &__media
        {
            left: 14px;
            width: calc(100% - 28px);
        }

        &__content
        {
            padding: 40px 20px;
        }

        &__title
        {
            margin-bottom: 20px;
        }
    }

    @include at-tablet
    {
        &__media
        {
            left: 80px;
            width: calc(100% - 160px);
        }

        &__content
        {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(flex-start);
        }

        &__title
        {
            margin-bottom: 10px;
        }
    }

    @include eq-tablet
    {
        &__content
        {
            padding: 60px;
        }
    }

    @include at-desktop
    {
        &__content
        {
            padding: 100px;
        }
    }
}

//--------------------------------------------------------------------//