
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Block Cards
//--------------------------------------------------------------------//

.block-cards
{
    &__container
    {
        position: relative;
    }

    &__label
    {
        display: block;
        color: $secondary;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }

    @include eq-mobile
    {
        &__heading
        {
            margin-bottom: 40px;
        }

        &__label
        {
            margin-top: 6px;
        }

        &__text
        {
            margin-top: 15px;
        }

        &__list
        {
            &-item
            {
                width: 100%;
    
                &:not(:first-child)
                {
                    margin-top: 20px;
                }
    
                .card-blog
                {
                    max-width: 350px;
                    margin: 0px auto;
                }
            }
        }
    }

    @include at-tablet
    {
        &__heading
        {
            margin-bottom: 70px;
        }

        &__label
        {
            margin-top: 3px;
        }

        &__text
        {
            margin-top: 30px;
        }

        &__list
        {
            margin-left: -15px;
            margin-right: -15px;

            &-item
            {
                padding: 0px 15px;
            }
        }
    }

    @include eq-tablet
    {
        &__list
        {
            @include justify-content(center);

            &-item
            {
                width: 50%;
                max-width: 350px;

                &:not(:nth-child(1)):not(:nth-child(2))
                {
                    margin-top: 30px;
                }
            }
        }
    }

    @include at-desktop
    {
        &__list
        {
            &-item
            {
                width: 25%;
            }
        }
    }
}

//--------------------------------------------------------------------//