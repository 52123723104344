
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Block Text Image
//--------------------------------------------------------------------//

.block-text-image
{
    &__container
    {
        position: relative;
    }

    &__media
    {
        picture
        {
            display: block;
            @include size(100%);
            aspect-ratio: 1;
            overflow: hidden;
            border-radius: 5px;

            img
            {
                display: block;
                @include size(100%);
                object-fit: cover;
            }
        }
    }

    &__text
    {
        margin-top: 20px;
    }

    &__cta
    {
        margin-top: 40px;
    }

    @include pre-desktop
    {
        &__content,
        &__media
        {
            width: 100%;
        }

        &__content
        {
            margin-top: 30px;
        }
    }

    @include at-desktop
    {
        &__content,
        &__media
        {
            width: 50%;
        }

        &__content
        {
            &.side-left
            {
                padding-left: 70px;
                padding-right: 70px;
            }

            &.side-right
            {
                padding-left: 70px;
                padding-right: 70px;
            }
        }
    }
}

//--------------------------------------------------------------------//