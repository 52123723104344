//##########################################//
//                                          //
//         MIXINS PER FUNZIONI CSS          //
//                                          //
//##########################################//


// Mixin for setting both width and height of an element
// @param $width: The width size.
// @param $height: The height size @default same of $width.
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Mixin for setting both max-width and max-height of an element
// @param $width: The max-width size.
// @param $height: The max-height size @default same of $width.
@mixin max-size($width, $height: $width) {
  max-width: $width;
  max-height: $height;
}

// Mixin for setting both min-width and hmin-eight of an element
// @param $width: The min-width size.
// @param $height: The min-height size @default same of $width.
@mixin min-size($width, $height: $width) {
  min-width: $width;
  min-height: $height;
}

@mixin stretch($offset-top: 0, $offset-right: 0, $offset-bottom: 0, $offset-left: 0) {
  position: absolute;
  @if $offset-top {
      top: $offset-top;
  }
  @if $offset-bottom {
      bottom: $offset-bottom;
  }
  @if $offset-left {
      left: $offset-left;
  }
  @if $offset-right {
      right: $offset-right;
  }
}


// Mixin to center things with position and transform properties
// @param $reset: Get the initial behaviour
@mixin center($reset: false) {
  @if $reset {
      @include stretch(initial, initial, initial, initial);
      transform: translate(0, 0);
      position: static;
  } @else {
      @include stretch(50%, initial, initial, 50%);
      transform: translate(-50%, -50%);
  }
}

// Mixin for centering horizontally
// @param $reset: Get the initial behaviour
@mixin centerX($reset: false) {
  @if $reset {
      transform: translateX(0);
      position: static;
      left: initial;
      right: initial;
  } @else {
      @include stretch(initial, initial, initial, 50%);
      transform: translateX(-50%);
  }
}

// Mixin for centering vertically
// @param $reset: Get the initial behaviour
@mixin centerY($reset: false) {
  @if $reset {
      transform: translateY(0);
      position: static;
      top: initial;
      bottom: initial;
  } @else {
      @include stretch(50%, initial, initial, initial);
      transform: translateY(-50%);
  }
}

// --------------------------------------------------
// Flexbox SASS mixins
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

// Box Pack
// start | center | end | justify
@mixin box-pack($align){
  -webkit-box-pack: $align;
  -moz-box-pack: $align;
  -ms-box-pack: $align;
  box-pack: $align;
}

// Box Align
// start | center | end | baseline | stretch
@mixin box-align($align){
  -webkit-box-align: $align;
  -moz-box-align: $align;
  -ms-box-align: $align;
  box-align: $align;
}


// --------------------------------------------------
// PX to EM mixins
// --------------------------------------------------
$browser-context: 16;

@function em($pixels, $context: $browser-context) {

  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return calc($pixels / $context * 1em);
}


// --------------------------------------------------
// Box Shadow mixins
// --------------------------------------------------
@mixin box-shadow($top, $left, $blur, $spread, $color, $inset: false) {
  @if $inset {
      -webkit-box-shadow:inset $top $left $blur $spread $color;
      -moz-box-shadow:inset $top $left $blur $spread $color;
      box-shadow:inset $top $left $blur $spread $color;
  } @else {
      -webkit-box-shadow: $top $left $blur $spread $color;
      -moz-box-shadow: $top $left $blur $spread $color;
      box-shadow: $top $left $blur $spread $color;
  }
}


// --------------------------------------------------
// Checkbox
// --------------------------------------------------
@mixin checkbox($borderColor, $flagColor){
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  padding: 10px;
  max-width: 16px;
  max-height: 16px;
  @include box-shadow(0px, 0px, 0px, 1px, $borderColor, true);
  background-color: $white;
  margin: 0px;
  border-radius: 5px;
  border: 0px;
  outline: none;

  &:checked
  {
    outline: none;
    font-style: normal;
    background-color: $flagColor;
    @include box-shadow(0px, 0px, 0px, 1px, $flagColor, true);
    
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0px;
      margin: 2px;
      width: 7px;
      height: 10px;
      color: $white;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      transform-origin: 100% 100%;
      transform: rotate(45deg);
    }
  }

  &:focus{
    border-color: $borderColor;
  }
}


// --------------------------------------------------
// Radio
// --------------------------------------------------
@mixin radio($borderColor, $flagColor){
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  padding: 10px;
  max-width: 16px;
  max-height: 16px;
  @include box-shadow(0px, 0px, 0px, 1px, $borderColor, true);
  background-color: $white;
  margin: 0px;
  border-radius: 10px;
  border: 0px;
  outline: none;

  &:checked
  {
    outline: none;
    font-style: normal;
    background-color: $flagColor;
    @include box-shadow(0px, 0px, 0px, 1px, $flagColor, true);
    
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0px;
      margin: 2px;
      width: 7px;
      height: 10px;
      color: $white;
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      transform-origin: 100% 100%;
      transform: rotate(45deg);
    }
  }

  &:focus{
    border-color: $borderColor;
  }
}