
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE MODAL
//--------------------------------------------------------------------//

.im-modal__open
{
    cursor: pointer;
}

.im-modal
{
    position: fixed;
    opacity: 0;
    visibility: hidden;
    top: 0px;
    left: 0px;
    @include size(100%);
    z-index: 99999;
    // transition: visibility 0s linear $timeFast, opacity $timeFast ease-in-out;

    &.open
    {
        display: block;
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear, opacity $timeFast ease-in-out;        
    }

    &.close
    {        
        transition: visibility 0s linear $timeFast, opacity $timeFast ease-in-out;
    } 

    &__background 
    {
        position: absolute;
        top: 0px;
        left: 0px;
        @include size(100%);
        background-color: rgba($black, 0.8);
        z-index: 1;
    }

    &__container
    {

        position: relative;
        @include center();
        background-color: $white;
        max-height: 100%;
        overflow-y: auto;
        z-index: 2;

        @include eq-mobile
        {
            @include size(80%, auto);
        }
        
        @include eq-tablet
        {
            @include size(720px, auto);
        }
    
        @include at-desktop
        {
            @include size(768px, auto);
        }

    }

    &__content
    {

        @include eq-mobile
        {
            padding: 25px;
        }
        
        @include eq-tablet
        {
            padding: 30px;
        }
    
        @include at-desktop
        {
            padding: 50px;
        }
        
        video, 
        picture,
        iframe 
        {
            width: 100%;
            height: auto;
        }

    }

    &__close
    {
        cursor: pointer;
        position: absolute;
        padding: 2px;
        z-index: 2;

        @include eq-mobile
        {
            top: 10px;
            right: 10px;
        }

        @include eq-tablet
        {
            top: 15px;
            right: 15px;
        }

        @include at-desktop
        {
            top: 25px;
            right: 25px;
        }

        svg
        {
            display: inline-block;
            @include size(24px);
            vertical-align: middle;
        }

    }


    //modale fullscreen
    &.full 
    {
        .im-modal__container
        {
            @include size(100%, auto);
            max-width: unset;
            padding: 0;
            background-color: transparent;
        }

        .im-modal__content 
        {
            position: relative;
            padding: 0;
            z-index: 1;
            
            video, 
            iframe 
            {
                max-width: 100vw;
                max-height: 100vh;
            }
        }

        .im-modal__close
        {
            border-radius: 50%;
            background-color: $white;
        }
    }
}

//--------------------------------------------------------------------//